import React from 'react';
import IndustriesSolutions from '../../IndustriesSolutions/IndustriesSolutions';

import { useIntl } from 'react-intl';

import bgImg from './assets/images/bg.png';

const TourismSolutions: React.FC = () => {
  const intl = useIntl();

  return (
    <IndustriesSolutions
      title={intl.formatMessage({id: 'industries.tourism.solutions.title',})}
      bg={bgImg}
      solutions={[
        {
          description: 'industries.tourism.solutions.solution_1.subtitle',
          link: '/payments/card',
          link_text: 'industries.tourism.solutions.link',
          title: 'industries.tourism.solutions.solution_1.title',
        },
        {
          description: 'industries.tourism.solutions.solution_2.subtitle',
          link: '/features/link-de-pago-smartlink',
          link_text: 'industries.tourism.solutions.link',
          title: 'industries.tourism.solutions.solution_2.title',
        },
        {
          description: 'industries.tourism.solutions.solution_3.subtitle',
          link: '/payments/transfer',
          link_text: 'industries.tourism.solutions.link',
          title: 'industries.tourism.solutions.solution_3.title',
        },
      ]}
    />
  )
};

export default TourismSolutions;
