import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import IndustriesBusinessTypes from '../../components/Industries/IndustriesBusinessTypes/IndustriesBusinessTypes';
import IndustriesCarousel from '../../components/Industries/IndustriesCarousel/IndustriesCarousel';
import IndustriesIntegration from '../../components/Industries/IndustriesIntegration/IndustriesIntegration';
import IndustriesPaymentMethods from '../../components/Industries/IndustriesPaymentMethods/IndustriesPaymentMethods';
import TourismFeatures from '../../components/Industries/Tourism/TourismFeatures/TourismFeatures';
import TourismHero from '../../components/Industries/Tourism/TourismHero/TourismHero';
import TourismSolutions from '../../components/Industries/Tourism/TourismSolutions/TourismSolutions';
import { LocaleTypes } from '../../data/locales';

interface TourismPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const TourismPage: React.FC<TourismPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="industries.tourism.title" />

      <TourismHero />

      <TourismFeatures />
      <IndustriesBusinessTypes
        title="industries.tourism.business_types.title"
        subtitle="industries.tourism.business_types.subtitle"
        list={[
          'industries.tourism.business_types.list.type_1',
          'industries.tourism.business_types.list.type_2',
          'industries.tourism.business_types.list.type_3',
          'industries.tourism.business_types.list.type_4',
          'industries.tourism.business_types.list.type_5',
        ]}
      />

      <TourismSolutions />

      <IndustriesPaymentMethods/>

      <IndustriesIntegration />

      <IndustriesCarousel
        list={[
          'education',
          'ecommerce',
          'services',
        ]}
      />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default TourismPage;
